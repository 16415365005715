<template>
  <div style="height: 100%;width: 100%;">
	<el-carousel indicator-position="none" style="height: 100vh;width: 100vw;" interval="60000">
		<el-carousel-item style="height: 100vh;width: 100vw;">
			<div id="app">
			  <Recipe/>
			</div>
		</el-carousel-item>
		<el-carousel-item style="height: 100vh;width: 100vw;">
			<div id="app">
			  <environment/>
			</div>
		</el-carousel-item>
	</el-carousel>
  </div> 
</template>

<script>
import Recipe from "./components/Recipe.vue";
import environment from "./components/environment.vue";


export default {
  name: "App",
  data() {
    return {value: "" };
  },
  methods: {
  },
  created() {
	  
  },
  components: {
    Recipe,
	environment
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
  width: 100%;
  font-size: 1vw;
}
#app {
  width: 100vw;
  height: 100vh;
  padding: 0 2vh;
  box-sizing: border-box;
}
#app {
  width: 100%;
  height: 100%;
  padding: 0 2vh;
  box-sizing: border-box;
}
</style>
