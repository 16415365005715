<template>
  <div class="hello" :style="{borderColor:color}">
    <div class="cover">
      <div class="main" :style="{backgroundColor:color}">
        <img
            v-if="!school_img"
            src="http://tyy-oss.oss-cn-huhehaote.aliyuncs.com/watermark/841d6fd11245ec0aa726c0fa15da3d95290f8e04.jpg"
            alt="园区头像"
            style="width: 30vh;"
          />
          <img
            v-if="school_img"
            :src="school_img"
            alt="园区头像"
            style="
              width:  30vh;
              margin-bottom: 0.4vw;
            "
          />
        <span style="color: #fff; padding-left: 1vw; font-size: 2.5vw">{{
            school_name
          }}</span>
          <div class="time-box" v-if="food">
            <div style="
            color: #fff;
            text-align: center;
            font-size: 1.5vw;
            line-height: 1;
            ">{{food[0].use_time ? food[0].use_time : '周一至周日'}}</div>
            <div style="
            color: #fff;
            text-align: center;
            font-size: 2vw;
            ">幼儿一周食谱第{{ stage | format() }}阶段</div>
          </div>
        
        <el-color-picker @change="colorSwatch" v-model="color"></el-color-picker>
      </div>
      
      <!-- <img src="../assets/images/food.png" alt="" class="bgimg"> -->
      <!-- style="background-image: url('../assets/images/food.png')" -->
      <div class="recipe-preview-box">
        <div class="recipe-preview" :class="item.stage == stage ? 'show' : ''" v-for="(item, index) in food" :key="index">
          <div class="box" v-if="item.generate_status == 1">
            <div class="title">
              <div class="left">
                <div class="col b-right-f b-bottom-f">项目</div>
                <div class="col b-right-f  b-bottom-f">名称</div>
                <div class="col b-right-f  b-bottom-f">用料</div>
                <div class="col b-right-f  b-bottom-f">净重/克</div>
              </div>
              <div class="title-right">
                <div class="col wide b-right-f b-bottom-f">小知识</div>
                <div class="col wide b-bottom-f">关于食材</div>
              </div>
            </div>
            <div class="content">
              <div class="left">
                <div class="item col">
                  <div
                    class="b-right-f font bc68CA9E f1vw"
                    :class="index != (item.jsonCategorizedData.length -1) ? 'b-bottom-f' :''"
                    :style="{height:`${ (v.projectheight / item.viewHeight ) * 100}%`}"
                    v-for="(v, k) in item.jsonCategorizedData"
                    :key="k"
                  >{{ v.type }}</div>
                </div>

                <div class="b-right-6 item col">
                  <div
                    class="font color1A8D5F bcbcE9F3EF"
                    :class="index != (item.itemData.length -1) ? 'b-bottom-6' :''"
                    :style="{height:`${ (v.itemHeight / item.viewHeight ) * 100}%`}"
                    v-for="(v, k) in item.itemData"
                    :key="k"
                  >{{ v.name }}</div>
                </div>
                <div class="b-right-6 item col">
                  <div
                    class="font bcbcE9F3EF"
                    :class="index != (item.ingredientData.length -1) ? 'b-bottom-6' :''"
                    :style="{height:`${ (v.height / item.viewHeight ) * 100}%`}"
                    v-for="(v, k) in item.ingredientData"
                    :key="k"
                  >{{ v.material }}</div>
                </div>
                <div class="b-right-6 item col">
                  <div
                    class="font bcbcE9F3EF"
                    :class="index != (item.ingredientData.length -1) ? 'b-bottom-6' :''"
                    :style="{height:`${ (v.height / item.viewHeight ) * 100}%`}"
                    v-for="(v, k) in item.ingredientData"
                    :key="k"
                  >{{ v.weight }}</div>
                </div>
              </div>
              <div class="right">
                <div class="col wide tipsCol">
                  <div
                    class="b-right-6 font b-bottom-6 tipsCol-view"
                    :style="{height:`${ (item.tipsHeight1 / item.viewHeight ) * 100}%`}"
                  >{{ item.tips[0] }}</div>
                  <div
                    class="b-right-6 font b-bottom-6 tipsCol-view"
                    :style="{height:`${ (item.tipsHeight2 / item.viewHeight ) * 100}%`}"
                  >{{ item.tips[1] }}</div>
                  <div
                    class="b-right-6 font tipsCol-view b-bottom-6"
                    :style="{height:`${ (item.tipsHeight3 / item.viewHeight ) * 100}%`}"
                  >{{ item.tips[2] }}</div>
                </div>
                <div class="Ingredients wide b-right-6 b-bottom-6">
                  <p v-for="(v,k) in item.typeExplain" :key="index">{{v}}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="bgimg"
            v-else-if="item.generate_status == 2 && (item.stage == stage)"
            :style="{ 'background-image': `url(${item.picture})` }"></div>
        </div>
        
      </div>
      
      <div class="bg-cover"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "HelloWorld",
  filters: {
    format(val) {
      switch (val) {
        case 1:
          return "一";
        case 2:
          return "二";
        case 3:
          return "三";
      }
    },
  },
  props: {
    school_id: [Number, String],
  },
  data() {
    return {
      stage: "",
      school_name: "",
      school_img: "",
      food: "",
      index: 0,
      color:'',
      start:'',
      poll:''
    };
  },
  watch: {
    school_id(val, oldVal) {
      clearInterval(this.start)
      clearInterval(this.poll)
      this.stage = ''
      this.food = ''
      this.getfood();
    },
    
  },
  methods: {
    getfood() {
      let that = this
      this.index = 0;
      axios
        .post("https://www.tuoyuyun.cn/edu/Tfoods/queryFoodsEnergy", {
          school_id: parseInt(283),
        })
        .then((res) => {
          this.school_img = res.data.data.school_img;
          this.school_name = res.data.data.school_name;
          this.food = res.data.data.food_data;
          console.log(this.food)
          this.food.forEach(item=>{
            item.use_time = this.formatDate(item.use_time)
            if(item.generate_status == 2){
              return
            }
            item.itemData = [];
            item.ingredientData = [];
            item.jsonCategorizedData.forEach((key, index) => {
              key.projectheight = 0;
              key.items.forEach(v => {
                v.itemHeight = v.ingredients.length;
                key.projectheight += v.itemHeight;
                item.itemData.push({
                  name: v.name,
                  itemHeight: v.itemHeight
                });
                v.ingredients.forEach(i => {
                  item.ingredientData.push({
                    material: i.material,
                    weight: i.weight,
                    height: 1
                  });
                });
              });
            });
            item.tipsHeight1 =
              item.jsonCategorizedData[0].projectheight +
              item.jsonCategorizedData[1].projectheight;
            item.tipsHeight2 =
              item.jsonCategorizedData[2].projectheight +
              item.jsonCategorizedData[3].projectheight;
            item.tipsHeight3 =
              item.jsonCategorizedData[4].projectheight +
              item.jsonCategorizedData[5].projectheight +
              item.jsonCategorizedData[6].projectheight +
              item.jsonCategorizedData[7].projectheight;
            item.viewHeight =
              item.tipsHeight1 +
              item.tipsHeight2 +
              item.tipsHeight3;
          })
          this.stage = res.data.data.food_data[this.index].stage;
          this.color = res.data.data.cookbook_color ? res.data.data.cookbook_color : '#43c386'
          this.index++;
          this.firstStartrun();
          this.pollData()
        });
    },
    pollData(){
      let that = this
      this.poll = setInterval(()=>{
        this.index = 0;
        axios.post("https://www.tuoyuyun.cn/edu/Tfoods/queryFoodsEnergy", {
          school_id: parseInt(283),
        })
        .then((res) => {
          
          this.school_img = res.data.data.school_img;
          this.school_name = res.data.data.school_name;
          this.food = res.data.data.food_data;
          this.food.forEach(item=>{
            item.use_time = this.formatDate(item.use_time)
            console.log(item.use_time)
            if(item.generate_status == 2){
              return
            }
            item.itemData = [];
            item.ingredientData = [];
            item.jsonCategorizedData.forEach((key, index) => {
              key.projectheight = 0;
              key.items.forEach(v => {
                v.itemHeight = v.ingredients.length;
                key.projectheight += v.itemHeight;
                item.itemData.push({
                  name: v.name,
                  itemHeight: v.itemHeight
                });
                v.ingredients.forEach(i => {
                  item.ingredientData.push({
                    material: i.material,
                    weight: i.weight,
                    height: 1
                  });
                });
              });
            });
            item.tipsHeight1 =
              item.jsonCategorizedData[0].projectheight +
              item.jsonCategorizedData[1].projectheight;
            item.tipsHeight2 =
              item.jsonCategorizedData[2].projectheight +
              item.jsonCategorizedData[3].projectheight;
            item.tipsHeight3 =
              item.jsonCategorizedData[4].projectheight +
              item.jsonCategorizedData[5].projectheight +
              item.jsonCategorizedData[6].projectheight +
              item.jsonCategorizedData[7].projectheight;
            item.viewHeight =
              item.tipsHeight1 +
              item.tipsHeight2 +
              item.tipsHeight3;
          })
          this.stage = res.data.data.food_data[this.index].stage;
          this.index++;
          console.log(this.food);
          clearInterval(this.start)
          this.startrun();
          this.color = res.data.data.cookbook_color ? res.data.data.cookbook_color : '#43c386'
          console.log(this.color)
        });
      }, 90000)
    },
    formatDate(time) {//时间戳转日期
      let date = new Date(time *1000);
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? ('0' + MM) : MM;
      let d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      let days = date.getDay();
      switch(days) {
        case 1:
            days = '星期一';
            break;
        case 2:
            days = '星期二';
            break;
        case 3:
            days = '星期三';
            break;
        case 4:
            days = '星期四';
            break;
        case 5:
            days = '星期五';
            break;
        case 6:
            days = '星期六';
            break;
        case 0:
            days = '星期日';
            break;
        }
      return  MM + '月' + d + '日'  + '  ' + days;
    },
    startrun() {
      if (this.food.length > 1) {
      this.start = setTimeout(() => {
          this.stage = this.food[this.index].stage;
          this.index++;
          this.index >= this.food.length ? (this.index = 0) : this.index;
          console.log(this.index);
          this.startrun();
        }, 10000);
      } else {
        console.log(this.index, this.food.length, "只有一个阶段");
      }
    },
    firstStartrun(){
      if (this.food.length > 1) {
        this.stage = this.food[0].stage;
        this.startrun()
      } else {
        console.log(this.index, this.food.length, "只有一个阶段");
      }
    },
    colorSwatch(){
      axios.post("https://www.tuoyuyun.cn/edu/Tfoods/updateSchoolColor", {
          school_id: parseInt(283),
          cookbook_color:this.color
        })
        .then((res) => {
          
        });
    },
  },
  mounted() {
    this.getfood();
  },
};
</script>

<style scoped lang="scss">

.bg-img, .bg-cover {
  background-color: #43c386;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.bg-cover {
  background-color: rgba(0,0,0,0);
}


.hello {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  height: 98vh;
  width: 97vw;
  border: 1vh;
  border-style: solid;
  border-color: #43c386;
  border-radius: 2vh;

  .cover {
    display: flex;
    flex-direction: column;
    height: 100%;

    .main {
      height: 14vh;
      background-color: #43c386;
      // width: 99%;
      width: 100.32%;
      display: flex;

      justify-content: space-between;
      line-height: 7vh;
      // margin:0.5vh 0.5vw 0 0.5vw;
      margin-top: -1px;
      margin-left: -2px;
      padding:0 2.5vw;
      box-sizing: border-box;
      align-items: center;
      border-top-left-radius: 1vh;
      border-top-right-radius: 1vh;
      position: relative;
      // border-radius: 1vh;
    }
    .bgimg {
      // height: 92vh;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 100%;
      border-radius: 15px;
    }
    
  }
}
.recipe-preview-box{
  width: 100%;
  height: 100%;
  padding:20px;
  overflow: hidden;
  box-sizing: border-box;
}

.recipe-preview{
  border: 1px solid #68CA9E;
  height: 0;
  opacity: 0;
  img{
    height: 100%;
    width: 100%;
  }
  .box{
    height: 100%;
    width: 100%;
    display: flex;
    font-size: 0.9vw;
    flex-direction: column;
    .title{
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-weight: 900;
      font-size: 1vw;
      color: #fff;
      box-sizing: border-box;
      background: #68CA9E;
      display: flex;
      .left{
        display: flex;
        width: 52%;
        .col{
          box-sizing: border-box;
          width: 25%;
          text-align: center;
        }
      }
      
      
      .title-right{
        flex: 1;
        display: flex;
        .wide{
          width: 50%;
          text-align: center;
        }
      }
    }
    .content{
      flex: 1;
      width: 100%;
      color: #585858;
      display: flex;
      .left{
        height: 100%;
        width: 52%;
        display: flex;
        .item{
          display: flex;
          flex-direction: column;
          .font{
            width: 100%;
            height: 100%;
            display: flex;
            box-sizing: border-box;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .right{
        flex: 1;
        height: 100%;
        display: flex;
        .wide{
          width: 50%;
        }
        .Ingredients{
          display: flex;
          justify-content: center;
          padding-left: 30px;
          padding-right: 30px;
          box-sizing: border-box;
          flex-direction: column;
        }
      }
      .col{
        box-sizing: border-box;
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
    }
    .b-left-f{
      border-left:1px solid #E7EAF6;
    }
    .b-right-f{
      border-right:1px solid #E7EAF6;
    }
    .b-right-6{
      border-right:1px solid #E7EAF6;
    }
    .b-bottom-6{
      border-bottom:1px solid #E7EAF6;
    };
    .b-bottom-f{
      border-bottom:1px solid #E7EAF6;
    };
    .bc68CA9E{
      background: #68CA9E;
      color: #fff;
    }
  }
  .bcbcE9F3EF{
    background: #F1FFF6;
  }
  .color1A8D5F{
    color: #1A8D5F;
  }
  .Ingredients p{
    font-weight: bold;
    line-height: 1.5;
  }
  .tipsCol{
    display: flex;
    flex-direction: column;
  }
  .tipsCol-view{
    display: flex;
    align-items: center;
    padding: 30px;
    box-sizing: border-box;
  }
}
.f1vw{
  font-size: 1vw;
  font-weight: bold;
}
.show{
  opacity:1;
  height: 100%;
  border:1px solid #fff;
}
.cursor{
  cursor: pointer;
}
.time-box{
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
 