<template>
  <div id="app">
      <div style="padding:10px 10px 0">
        <!-- 大框框 -->
        <div class="content" :style="{borderColor:colorA,backgroundColor:colorA}">
          <!-- 框框头部 -->
          <div class="cont_header" :style="{backgroundColor:colorA}">
            <div class="img_ceter">
              <img :src="logoimg" alt />
            </div>
            <div class="center">
              <!-- <img src="./assets/img/logo.png" alt=""> -->
              <span>{{ schoolName }}</span>
            </div>
            <div class="header_time">{{ nowDate }}&emsp;{{ nowTime }} &emsp; {{ nowWeek }}</div>
            <el-color-picker @change="colorSwatch" v-model="colorA"></el-color-picker>
          </div>
          <!-- 中部卡片 -->
          <div class="cont_body" :style="{ height: height,borderColor:colorA}" >
            <div class="data">
              <el-card v-cloak class="card1" :style="{borderColor:colorA}">
                <p class="title">PM2.5</p>
               
                <p class="num" :style="{ color: queryNewestData.PM25_color }">{{ onlineData[0].PM25 }}</p>
                <p class="unit">μg/m³</p>
              </el-card>
              <el-card v-cloak class="card2" :style="{borderColor:colorA}">
                <p class="title">TVOC</p>
                <p class="num" :style="{ color: queryNewestData.TVOC_color }">{{ queryNewestData.TVOC }}</p>
                <p class="unit">mg/m³</p>
              </el-card>
              <el-card v-cloak class="card3" :style="{borderColor:colorA}">
                <p class="title">温度</p>
                <p class="num" :style="{ color: queryNewestData.TEMP_color }">{{ queryNewestData.TEMP }}</p>
                <p class="unit">℃</p>
              </el-card>
              <el-card v-cloak class="card4" :style="{borderColor:colorA}">
                <p class="title">湿度</p>
                <p class="num" :style="{ color: queryNewestData.H2_color }">{{ queryNewestData.H2 }}</p>
                <p class="unit">%RH</p>
              </el-card>
              <el-card v-cloak class="card5" :style="{borderColor:colorA}">
                <p class="title">甲醛</p>
                <p class="num" :style="{ color: queryNewestData.HCHO_color }">{{queryNewestData.HCHO }}</p>
                <p class="unit">ppm</p>
              </el-card>
              <el-card v-cloak class="card6" :style="{borderColor:colorA}" v-if="newstatus==1">
                <p class="title">TDS</p>
                <p class="num" :style="{ color: queryNewestData.pureWaterTDS_color }">{{ queryNewestData.pureWaterTDS }}</p>
                <p class="unit">mg/L</p>
              </el-card>
            </div>
            <!-- 下面列表和表格框框 -->
            <div class="right_cont">
              <!-- 班级列表 -->
              <div class="left_nav" >
                <!-- <div v-for="(item,index) in classNum" id="nav_item" :class="{active:num==index}"  :key="item.DEVICE_ID" @click="getMessage(item.id)">  -->
                <div
                  v-for="(item, index) in listTab"
                  :class="{ active: activeIndex == index }"
                  :style="{color:(activeIndex == index ? '#ffffff' : colorA), background:(activeIndex == index ? colorA : '#ffffff'),borderColor:colorA}"
                  :data-index="index + '--' + activeIndex"
                  :key="item.class_id"
                  @click="clickTab(item.class_id, index)"
                >
                  <p>{{ item.name }}</p>
                 <span v-if="item.deviceStatus==1">*</span>
                  
                </div>
              </div>
              <!-- 表格 -->
              
              <div class="data_table">

                <el-table
                  :data="onlineData"
                  :header-cell-style="{background:colorA}"
                  stripe
                  style="width: 100%"
                  
                >
                  <el-table-column fixed prop="add_time" label="时间">
                    <template slot-scope="scope">
                      <p :style="{color:colorA}">{{ scope.row.add_time }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="PM25" label="PM2.5" >
                    <template slot-scope="scope">
                      <p :style="{color:colorA}">{{ scope.row.PM25 }} ug/m³</p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="TVOC" label="TVOC" >
                    <template slot-scope="scope">
                      <p :style="{color:colorA}">{{ scope.row.TVOC }} mg/m³</p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="TEMP" label="温度">
                    <template slot-scope="scope">
                      <p :style="{color:colorA}">{{ scope.row.TEMP }} ℃</p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="H2" label="湿度" >
                    <template slot-scope="scope">
                      <p :style="{color:colorA}">{{ scope.row.H2 }} %RH</p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="HCHO" label="甲醛">
                    <template slot-scope="scope">
                      <p :style="{color:colorA}">{{ scope.row.HCHO }} ppm</p>
                    </template>
                  </el-table-column>
                  <el-table-column  prop="pureWaterTDS" label="TDS"  v-if="newstatus==1"  width="100%">
                    <template slot-scope="scope">
                      <p :style="{color:colorA}">{{ scope.row.pureWaterTDS }} mg/L</p>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
        <el-dialog title="客户ID" :visible.sync="dialogFormVisible" width="350px">
          <el-form :model="form">
            <el-form-item>
              <el-input type="number" v-model="form.schoolId" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="getSchoolId">确 定</el-button>
          </div>
        </el-dialog>
      </div>
  </div>
</template>

<script>
import ScreenAdapter from "../components/ScreenAdapter.vue";
export default {
  name: "App",
  data() {
    return {

    
      nowDate: "", //当前年月
      nowTime: "", // 当前时间
      nowWeek: "", // 当前星期
      
      colors: [{ color: "#43c386" }],
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight, //实时屏幕高度
      height: "600px",
      newstatus:2,//是否有水质
      // deviceStatus:1,//是否在线
      newsclass_id:"",
      logoimg:'http://tyy-oss.oss-cn-huhehaote.aliyuncs.com/watermark/841d6fd11245ec0aa726c0fa15da3d95290f8e04.jpg',
      //最新数据
      queryNewestData:{
        id: 1527828,
            add_time: "2022-06-13 10:30:04",
            TVOC: "0.0",
            HCHO: "0.0000",
            PM25: "0.0",
            H2: "0.0",
            TEMP: "0.0",
            device_id: 21,
            TVOC_bfb: 0,
            HCHO_bfb: 0,
            PM25_bfb: 0,
            H2_bfb: 0,
            TEMP_bfb: 0,
            pureWaterTDS: "",
            PM25_text: "优+",
            PM25_color: "#43c386",
            PM25_percentage: "8.0",
            H2_text: "舒适",
            H2_color: "#43c386",
            H2_percentage: "69.5",
            TVOC_color: "#43c386",
            TEMP_color: "#43c386",
            HCHO_color: "#43c386",
            pureWaterTDS_color:"#43c386"
      },
      listTab: [
        {
          class_id:30,
          deviceStatus:2,
          name: "小兔乖乖班",
          state: 1,
          device_num: 0,
          school_id: 283,
          status:1,
        }
      ],
      titleData: [],
      // 班级信息
      onlineData: [
        {
          id: 6583,
          add_time: "00-00-00",
          TVOC: "0.0",
          TVOC_color: "#43c386",
          HCHO:"0.0000",
          pureWaterTDS:0,
          PM25:"0.0",
          PM25_text: "低",
          H2: "0.0",
          H2_text: "低",
          TEMP: "0.0",
          TEM_color: "#43c386",
          device_id: 0,
          TVOC_bfb: 0,
          HCHO_bfb: 0,
          PM25_bfb: 0,
          H2_bfb: 0,
          TEMP_bfb: 0
        }
      ], //实时数据
      num: 0, //
      activeIndex: 0,
      set_time: null,
      schoolName: "",
      schoolId: 20,
      dialogFormVisible: false,
      form: {
        schoolId: ""
      },
      colorA:'',
    };
  },
  components: {
    ScreenAdapter
  },
  created() {
    console.log(this.windowHeight);
    // let schoolId = this.getUrlKey('schoolId');
    // this.schoolId = schoolId;
    let h = parseInt(this.windowHeight * 0.17);
    console.log(h);
  
    this.height = this.windowHeight - h + "px";
    console.log(this.height);
    
    if (localStorage.getItem("schoolId")) {
      this.schoolId = localStorage.getItem("schoolId");
    }
    
   this.getClass();
    
  },
  mounted() {
    this.timer = setInterval(() => {
      this.setNowTimes();
    }, 1000);
  },
  methods: {
    //获取最新数据
    getNewdata(){
      this.$http
        .post("edu/huanjing/queryNewestData",  {class_id:this.newsclass_id, school_id: 283} )
        .then(res => {
         // console.log(res.data.data);
          console.log( this.listTab[this.activeIndex].name);
          this.listTab[this.activeIndex].deviceStatus = res.data.data.deviceStatus
          if (res.data.status == 1) {
            let {environment,status}=res.data.data
            this.newstatus=status;
             
            this.queryNewestData = environment;
           // this.clickTab(this.listTab[this.activeIndex].id, this.activeIndex);
           // this.timeTab();
            //this.schoolName = res.data.school_name;
          } else if(res.data.status == 0){
           // this.$message.error(res.data.msg);
           this.queryNewestData = this.queryNewestData 
          }
        })
        .catch(err => {
          //this.$message.error(err);
        });
    },
    // 获取班级信息
    getClass() {
      this.$http
        .post("edu/huanjing/queryClass",  { school_id: 283 } )
        .then(res => {
          console.log(res);
          if (res.data.status == 1) {
            this.colorA = res.data.ambient_color ? res.data.ambient_color : '#43c386'
            this.listTab = res.data.data;
            this.clickTab(this.listTab[this.activeIndex].class_id, this.activeIndex);
            this.timeTab();
            this.schoolName = res.data.school_name;
            this.logoimg = res.data.school_img
            
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(err => {
          this.$message.error(err);
        });
    },
    refresh() {
      location.reload();
    },
    lookId() {
      this.dialogFormVisible = true;
    },
    getSchoolId() {
      if (this.form.schoolId.trim() == "") {
        this.$message.warning("请输入Id");
        return;
      }
      this.schoolId = this.form.schoolId;
      localStorage.setItem("schoolId", this.schoolId);
      clearInterval(this.set_time);
      this.activeIndex = 0;
      this.getClass();
      this.form.schoolId = "";
      this.dialogFormVisible = false;
    },
    clickTab(class_id, index) {
      
      this.newsclass_id=class_id
       this.getNewdata()
      this.activeIndex = index;
      this.onlineData = [
        {
          id: 6583,
          add_time: "00-00-00",
          TVOC: "0.0",
          TVOC_color: "#43c386",
          HCHO:"0.0000",
          pureWaterTDS:0,
          PM25:"0.0",
          PM25_text: "低",
          H2: "0.0",
          H2_text: "低",
          TEMP: "0.0",
          TEM_color: "#43c386",
          device_id: 0,
          TVOC_bfb: 0,
          HCHO_bfb: 0,
          PM25_bfb: 0,
          H2_bfb: 0,
          TEMP_bfb: 0
        }
      ]
      this.$http
        .post("edu/huanjing/queryData", { class_id: class_id ,school_id:283} )
        .then(res => {
          console.log("...", res.data.data);
          if (res.data.status == 1) {
            res.data.data.map(item => {
              item.add_time = item.add_time.slice(11);
              item.PM25 == 0 ? item.PM25 - 0 + 1 : item.PM25;
            });
            console.log("改变：", res.data.data);
            this.onlineData = res.data.data;
          } else {
           // this.$message.warning(res.data.msg);
            return;
          }
        });
      //  this.timeTab()
    },
    //定时切换
    timeTab() {
      
      this.set_time = setInterval(() => {
        this.activeIndex++;
        if (this.activeIndex >= this.listTab.length) {
          this.activeIndex = 0;
        }
        let classId = this.listTab[this.activeIndex].class_id;
        this.clickTab(classId, this.activeIndex);
      }, 10000);
    },
    colorSwatch(){
      this.$http.post("https://www.tuoyuyun.cn/edu/huanjing/updateSchoolColor", {
          school_id: parseInt(283),
          ambient_color:this.colorA
        })
        .then((res) => {
          
        });
    },
    // 当前时间
    setNowTimes() {
      let myDate = new Date();
      // console.log(myDate)
      let wk = myDate.getDay();
      let yy = String(myDate.getFullYear());
      let mm =
        myDate.getMonth() + 1 < 10
          ? "0" + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1;
      let dd = String(
        myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate()
      );
      let hou = String(
        myDate.getHours() < 10 ? "0" + myDate.getHours() : myDate.getHours()
      );
      let min = String(
        myDate.getMinutes() < 10
          ? "0" + myDate.getMinutes()
          : myDate.getMinutes()
      );
      let sec = String(
        myDate.getSeconds() < 10
          ? "0" + myDate.getSeconds()
          : myDate.getSeconds()
      );
      let weeks = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
      ];
      let week = weeks[wk];
      this.nowDate = yy + "-" + mm + "-" + dd;
      // this.nowTime = hou + ':' + min + ':' + sec
      this.nowTime = hou + ":" + min;
      this.nowWeek = week;
      // this.formdate = yy + '-' + mm + '-' + dd + ' ' + hou + ':' + min + ':' + sec
    },
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    }
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
  width: 100%;
  font-size: 1vw;
}
#app {
  width: 100%;
  height: 100%;
  padding: 0 2vh;
  box-sizing: border-box;
}
.header {
  height: 8vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1vw;
  color: #43c386;
  line-height: 8vh;
}
.header .header_center {
  width: 50%;
  text-align: center;
}
.header .header_center span {
  background: #e9e9e9;
  margin: 0 5%;
  padding: 5px 20px;
  border-radius: 15px;
  cursor: pointer;
}
.content {
  border-radius: 20px;
  background-color: #43c386;
  border: 1px solid #43c386;
  text-align: center;
  overflow: hidden;
}
.cont_header .center {
  display: flex;
  height: 14vh;
  line-height: 14vh;
  align-items: center;
}
.cont_header {
  background: #43c386;
  height: 14vh;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;

  justify-content:space-between;
  padding:0 2.5vw;
  box-sizing: border-box;
  align-items: center;
}
.cont_header .header_time {
  color: #fff;
  font-size: 2vw;
}
.cont_header .center span {
  color: #fff;
  font-size: 2.5vw;
}
/* .cont_header .img_ceter{

} */
.cont_header .img_ceter{
  width: 13vw;
}
.cont_header .img_ceter img {
  /* padding-right: 50px; */
  width: 13vw;
}
.cont_body {
  width: 100%;
  /* margin: 0 20px 20px 20px; */
  border-top: 0 !important;
  border:10px solid #43c386;
  background-color: #fff;
  overflow: hidden;
  border-radius: 15px 15px;
  box-sizing: border-box;
  /* padding: 20px; */
  /* display: flex; */
  
  overflow: hidden;
}
.cont_body .left_nav {
  flex: 0 0 13.5%;
  /* overflow: hidden;
  overflow-y: scroll; */
  /* width: 13%; */
  margin-right: 15px;
  /* border-right: 1px solid #43c386; */
  height:100%;
  /* background: #43c386; */
  border-bottom-left-radius: 20px;
}
.cont_body .left_nav div {
  border:1px solid rgb(67, 195, 134);
  background-color: #fff;
  border-radius: 10px;
  line-height: 5vh;
  margin-bottom: 1vh;
  position: relative;
  box-sizing: border-box;
  /* padding: 1vh 1vh; */
}
/* .cont_body .left_nav div:nth-child(1) {
  border-top: 1px solid #fff;
} */
.cont_body .left_nav div.active {
  background: rgb(67, 195, 134);
}
.cont_body .left_nav div p {
  font-size: 1.4vw;
  /* font-weight: bold; */
  /* padding-bottom: 5px; */
}
.cont_body .left_nav div span {
  position: absolute;
  left: 90%;
  bottom:10%;
  font-size: 2vw;
 
}
.cont_body .left_nav div.active p {
  color: #fff;
}
.cont_body .left_nav div.active span {
  color: #fff;
  /* background: url(../assets/img/active.png) no-repeat left center; */
}
.cont_body .right_cont {
  /* flex: 0 0 85%; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
}
.data {
  display: flex;
  justify-content: space-around;
  padding: 6vh 0;
}
.data .el-card {
  border: 2px solid #43c386;
  width: 15%;
  height: 15%;
  border-radius: 15px;
}
.data .el-card .title{
  font-size:2vw;
}
.card1 {
  width: 17%;
  text-align: left;
  background: url(../assets/img/PM2.5.png) no-repeat 85% bottom;
  background-size: 80% 100%;
}
.data_table {
  width:100%;
  
}
.el-table {
  
  width: 100%;
  border-radius:10px;
}


.el-table .cell,
.el-table--border td:first-child .cell,
.el-table--border th:first-child .cell {
  padding-left: 0 !important;
}
.el-table th > .cell {
	
  padding-right: 0 !important;
}
.el-table .cell {
  
  padding-right: 0 !important;
}
/deep/ .el-table th>.cell{
	
	color: #fff !important;
}
.el-table th{
	
}
.el-table th > .cell {
  text-align: center;
}
.el-table .cell {
  text-align: center;
}
/* .el-table--border td,
.el-table--border th,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid #43c386 !important;
} */
.el-table td,
.el-table th.is-leaf {
  /* border-bottom: 1px solid #43c386 !important; */
   border:0!important;
}
.el-table .cell {
  /* line-height: auto !important; */
  padding:0 !important;
	height:3.5vh;
  line-height:3.5vh !important;
}
.el-table th {
  /* border-radius: 15px; */
}
.el-table tr:nth-child(2n) {
  background: #ddd !important ;
}
.el-table {
  font-size: 1vw !important;
}
.el-table thead {
  color: #fff !important;
  font-size: 1.4vw !important;
}
/* .el-table th{line-height: 1.5vw !important;}
.el-table th .cell{line-height: 1.5vw !important;display: block !important;} */
.el-table--border td:last-child,
.el-table--border th.el-table_1_column_6:last-child,
.el-table__body-wrapper
  .el-table--border.is-scrolling-left
  ~ .el-table__fixed:last-child {
  border-right: 0 !important;
}

.el-card .title {
  text-align: left;
  font-size: 2vh;
}
/* .el-card .el-card__body {
  padding: 2vh;
} */
.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: 8px 6px 12px 0 rgba(0, 0, 0, 0.1) !important;
}
/* .el-card  {
  border: 2px solid #43c386;
} */
.card1 .el-card__body {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.card1 .el-card__body p.humidity_status {
  position: absolute;
  z-index: 999;
  top: 35%;
  left: 45%;
}
/* .card1 .el-card__body p.humidity_num {
  position: absolute;
  left: 0;
  top: 45%;
  font-size: 26px;
  font-weight: bold;
  width: 100%;
  text-align: center;
} */
/* .card1 .el-card__body p.humidity_prent {
  position: absolute;
  left: 46%;
  top: 64%;
} */
/* .card2 .el-card__body {
  position: relative;
}
.card2 .el-card__body p.humidity_status {
  position: absolute;
  z-index: 999;
  top: 31%;
  left: 46%;
} */
/* .card2 .el-card__body p.humidity_num {
  position: absolute;
  left: 0;
  top: 45%;
  font-size: 26px;
  font-weight: bold;
  width: 100%;
  text-align: center;
}
.card2 .el-card__body p.humidity_prent {
  position: absolute;
  left: 42%;
  top: 64%;
} */

.card2 {
  width: 16%;
  text-align: left;
  background: url(../assets/img/tvoc.png) no-repeat 85% bottom;
  background-size: 80% 100%;
}
.card3 {
  width: 16%;
 
  text-align: left;
  background: url(../assets/img/温度.png) no-repeat 85% bottom;
  background-size: 80% 100%;
  /* text-align: left; */
}
.card4 {
  width: 16%;
 
  text-align: left;
  background: url(../assets/img/湿度.png) no-repeat 95% bottom;
  background-size: 80% 90%;
}
.card5 {
 width: 16%;
  
  text-align: left;
  background: url(../assets/img/甲醛.png) no-repeat 85% bottom;
  background-size: 90px 100%;
}
.card6 {
 width: 16%;
 
  text-align: left;
  background: url(../assets/img/水质.png) no-repeat 85% bottom;
  background-size: 80% 100%;
}
.card1 p.num,
.card2 p.num,
.card3 p.num,
.card4 p.num,
.card5 p.num,
.card6 p.num {
  font-size: 3.5vw;
  font-weight: bold;
}
.card1 p.unit,
.card2 p.unit,
.card3 p.unit,
.card4 p.unit,
.card5 p.unit,
.card6 p.unit {
  font-size: 2vw;
}
.el-progress-circle__track {
  stroke: #e8e8e8;
}
.card2 .el-progress-circle__track {
  stroke: #e8e8e8;
}
.el-button--primary {
  background: #43c386 !important;
  border-color: #43c386 !important;
}
.el-button--primary:hover {
  background: #57e7a2 !important;
  border-color: #57e7a2 !important;
}
.el-button--default:hover {
  color: #57e7a2 !important;
  border-color: #57e7a2 !important;
}
/*.data_table{width: 100%;
    font-size: 1vw;
    box-sizing: border-box;
    margin-bottom: 0;
    border-top: 1px solid #43c386;
    /* box-shadow: inset 0 0 1vw #1e5a88; */
/* content: 'viewport-units-buggyfill; font-size: 1vw; padding: 0px 2vw; margin-bottom: 0vw; -webkit-box-shadow: 0 0 1vw #1E5A88 inset; box-shadow: 0 0 1vw #1E5A88 inset';} */
/*.list,.table{width:100%;display: flex;display: -ms-flexbox;}
.table{    height: 100%;
    -ms-flex-direction: column;
    flex-direction: column;}
.list{margin:0 auto;}
.list>div{width: 20%;
    height: 6vh;
    line-height: 6vh;
    font-size:1vw;
    padding: 0;
    color:#43c386;
    border-bottom: 1px solid #43c386;
    border-left: 1px solid #43c386;}
  
.list:nth-child(2n-1){background: #ddd;}
.list:nth-child(1) div{font-size:1.5vw;font-weight: bold;}
.list div:nth-child(1){border-left:none;} */

@media only screen and (max-width: 1000px) {
  .header {
    height: 8vh;
    display: flex;
    font-size: 1.5vw;
    line-height: 8vh;
  }
  .cont_header .center {
    height: 12vh;
    line-height: 12vh;
    align-items: center;
  }
  .cont_header {
    height: 12vh;
  }
  .cont_header .center span {
    color: #fff;
    font-size: 3vw;
  }
  .el-progress-circle {
    width: 90px !important;
    height: 90px !important;
  }
  .card1 .el-card__body p.humidity_num,
  .card2 .el-card__body p.humidity_num {
    top: 42%;
  }
  /* .card3 p.num,
  .card4 p.num,
  .card5 p.num {
    padding-top: 2vh;
  } */
  .cont_header .center img {
    padding-right: 20px;
    height: 6vh;
  }
  .card1 .el-card__body p.humidity_status {
    top: 32%;
    left: 43%;
  }
  /* .list>div{
    height: 6vh;
    line-height: 6vh;
    font-size:1.5vw;
}
.list:nth-child(2n-1){background: #ddd;}
.list:nth-child(1) div{font-size:2vw;font-weight: bold;} */
  .el-table td,
  .el-table th {
    padding: 5px 0 !important;
  }
}
@media only screen and (max-width: 750px) {
  .el-progress-circle {
    width: 60px !important;
    height: 60px !important;
  }
}
[v-cloak] {
  display: none;
}
</style>
